#container {
  width: 100%;
  max-width: 1280px;
  height: 100%;
  margin: 0 auto;
  padding: 88px 48px 48px 48px;
  // background-color: antiquewhite;

  @media (max-width: 900px) {
    padding-left: 32px;
    padding-right: 32px;
  }
  @media (max-width: 600px) {
    padding-left: 16px;
    padding-right: 16px;
  }
}

.chartEvolution {
  display: flex;
  flex-direction: column;
  position: relative;

  .YAndChart {
    display: flex;
    flex-direction: row;
  }

  .XAxis {
    height: 25px;
    display: flex;
    align-items: flex-end;
    justify-content: space-between;

    > div {
      width: 15px;
      height: 25px;
      font-weight: 400;
      font-size: 10px;
      line-height: 12px;
      text-align: center;
      color: #6f7d81;
      &.selected {
        color: black;
      }
    }
  }
}

.tooltip {
  position: absolute;
  top: 20px;
  background-color: beige;
  width: 60px;
}

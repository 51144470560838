#activities {
  margin-top: 32px;

  > .wrapper {
    // max-width: 1118px;
    height: 418px;
    margin: 16px auto 0 auto;
    background: #ffffff;
    border: 2px solid #dee1e3;
    border-radius: 8px;
    overflow-y: auto;
    // overflow-x: hidden;
    padding: 0 16px 12px 16px;
  }

  > .wrapperHide {
    height: 0;
    border-top: 0;
    border-bottom: 0;
    padding-bottom: 0;
  }

  table.activityTable {
    width: 100%;
    margin: 0 auto;
    // max-width: 1114px;
    border-collapse: collapse;
    border: none;
    border-spacing: 0;

    thead {
      position: sticky;
      top: 0;
      height: 54px;
      vertical-align: top;
      text-align: left;
      background-color: white;

      th {
        padding-top: 16px;
        padding-left: 16px;
        font-weight: 600;
        font-size: 14px;
        line-height: 17px;
        &:first-child {
          padding-left: 0;
        }
      }
    }

    tbody {
      tr {
        // height: 68px;
        // max-height: 68px;
        border-bottom: 1px solid #dee1e3;
        font-weight: 400;
        font-size: 12px;
        line-height: 14px;
        color: #3c3b3b;
      }
    }

    td {
      padding-left: 0;
      text-align: center;
      // padding-bottom: 15px;
      vertical-align: center;
      padding: 15px 0;
      &:first-child {
        text-align: left;
      }

      &.left {
        padding-left: 16px;
        text-align: left;
      }
      .centerOperation {
        display: flex;
        justify-content: center;
        // padding-top: 15px;
      }
    }
  }

  > .wrapperCard {
    display: flex;
    overflow-x: auto;
    column-gap: 8px;
    padding-bottom: 16px;
    font-weight: 400;
    font-size: 12px;
    line-height: 14px;
    color: #3c3b3b;

    /*
    margin-right: -48px;
    padding-right: 48px;

    @media (max-width: 900px) {
      margin-right: -32px;
      padding-right: 32px;
    }
    @media (max-width: 600px) {
      margin-right: -16px;
      padding-right: 16px;
    }
    */

    > div {
      height: 326px;
      width: 320px;
      min-width: 320px;
      background: #ffffff;
      border: 2px solid #dee1e3;
      border-radius: 8px;
      padding: 14px;
      display: flex;
      flex-direction: column;
      row-gap: 16px;
      overflow: auto;

      .cardId {
        display: flex;
        justify-content: space-between;
        > div {
          display: flex;
          align-items: center;
          background-color: red;
        }
      }

      .operation {
        display: flex;
        flex-direction: row;
        column-gap: 8px;
        > div {
          display: flex;
          flex-direction: column;
          align-items: center;
          row-gap: 8px;
          background-color: pink;
        }
      }

      strong {
        font-weight: 600;
        font-size: 14px;
        line-height: 17px;
        color: #000000;
        margin-right: 8px;
      }
    }
  }

  .activityDate {
    color: #777777;
    min-width: 100px;
  }

  .activityOperation {
    width: 38px;
    height: 38px;
    border-radius: 6.62px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 700;
    font-size: 14px;
    line-height: 17px;
    color: #241200;
  }
}

%button {
  margin-bottom: 14px;
  height: 144px;
  min-width: 24px;
  button {
    width: 24px;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    border: none;
    background-color: white;
    border-radius: 2px;
    padding: 0;
    cursor: pointer;
    &:hover:enabled {
      background-color: gainsboro;
    }
    &:disabled {
      cursor: auto;
      svg {
        stroke: #6f7d81;
      }
    }
  }
}

.chartBox {
  width: 551px;
  min-height: 326px;
  background: #ffffff;
  border: 2px solid #dee1e3;
  border-radius: 8px;
  padding: 14px;
  display: flex;
  flex-direction: column;
  position: relative;

  @media (max-width: 1228px) {
    width: 100%;
  }
  // @media (max-width: 400px) {
  //   width: 551px;
  //   // margin-left: -16px;
  //   // margin-right: -16px;
  //   // border: none;
  //   // border-radius: 0;
  // }

  > label {
    font-weight: 600;
    font-size: 18px;
    line-height: 22px;
  }

  .legend {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin: 16px 13px 0 0;
    align-self: flex-end;
    column-gap: 8px;

    > div {
      display: flex;
      align-items: center;

      > div {
        width: 10px;
        height: 10px;
      }
      > span {
        margin-left: 4px;
        font-weight: 400;
        font-size: 14px;
        line-height: 17px;
        color: #6f7d81;
      }
    }
  }

  > .wrapper {
    flex: 1;
    display: flex;
    flex-direction: row;
    align-items: flex-end;

    .y-axis {
      position: relative;
      min-width: 18px;

      .y-label {
        position: absolute;
        left: 50%;
        bottom: 14px;
        height: 144px;
        div {
          transform: translateX(-50%) translateY(-50%) rotate(270deg);
          position: relative;
          top: 50%;

          font-weight: 400;
          font-size: 12px;
          line-height: 14px;
          color: #6f7d81;
          white-space: nowrap;
        }
      }

      span {
        font-weight: 400;
        font-size: 12px;
        line-height: 14px;
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        bottom: 0;
        white-space: nowrap;
      }
    }

    .button_left {
      @extend %button;
      transform: rotate(180deg);
    }

    .yAxis {
      z-index: 10;
      height: 176px;
      width: 14px;
      margin-bottom: 22px;
      margin-right: 4px;
      display: flex;
      flex-direction: column-reverse;
      justify-content: space-between;
      align-items: center;
      font-weight: 400;
      font-size: 12px;
      line-height: 14px;
      text-align: center;
      color: #6f7d81;
    }

    .chart {
      flex: 1;
      height: 100%;
      margin: 0 4px;
      padding: 0;
      display: flex;
      position: relative;

      .chartTooltip {
        position: absolute;
        bottom: 218px;
        white-space: nowrap;
        display: flex;
        flex-direction: row;
        transform: translateX(-50%);

        > div {
          display: flex;
          flex-direction: column;
          align-items: center;

          > div {
            width: 6px;
            height: 6px;
            margin: 1px 8px 0 8px;
          }
          > span {
            font-weight: 600;
            font-size: 12px;
            line-height: 14px;
          }
        }
      }

      .innerWraper {
        flex: 1;
        height: 100%;
        overflow: hidden;
        position: relative;
      }
      .inner {
        display: flex;
        flex-direction: row;
        // column-gap: 16px;
        position: absolute;
        bottom: 0;
        // left: 0;
        transition: left 200ms cubic-bezier(0, 0, 0.2, 1) 0ms;
      }
    }

    .button_right {
      @extend %button;
    }
  }
}

#kpis {
  margin-top: 32px;

  > .wrapper {
    padding-top: 16px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: flex-end;
    justify-content: center;
    gap: 16px;
  }

  .kpi {
    width: 98px;
    text-align: center;

    > label {
      font-weight: 500;
      font-size: 14px;
      line-height: 17px;
    }
    > .box {
      margin-top: 8px;
      width: 98px;
      height: 98px;
      background: #ffffff;
      border: 2px solid #dee1e3;
      border-radius: 8px;
      padding-bottom: 14px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: flex-end;
      position: relative;

      > .number {
        font-weight: 700;
        font-size: 32px;
        line-height: 38px;
        color: #7a48ff;
        margin-top: -4px;
      }
      > .text {
        font-weight: 400;
        font-size: 14px;
        line-height: 17px;
        color: #6f7d81;
      }
    }
  }
}

%button {
  position: absolute;
  top: 2px;
  right: 2px;
  width: 48px;
  height: 48px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  padding: 0;
  margin: 0;
  cursor: pointer;
}

.infoButton {
  @extend %button;
  background-color: white;
}

.infoText {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-color: rgba(222, 225, 227, 0.95);
  z-index: 100;
  padding-bottom: 14px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  text-align: left;

  > label {
    font-weight: 600;
    font-size: 18px;
    line-height: 22px;
    padding: 14px;
  }

  > button {
    @extend %button;
    background-color: rgba(222, 225, 227, 0.95);
  }

  > .text {
    flex: 1;
    position: relative;

    > div {
      position: absolute;
      left: 0;
      top: 0;
      right: 0;
      bottom: 0;
      padding: 4px 14px 0 14px;
      overflow-y: auto;
      font-weight: 400;
      font-size: 14px;
      line-height: 17px;
    }
  }
}

.infoButtonKpi {
  width: 36px;
  height: 36px;
}

.infoTextKpi {
  top: -2px;
  left: -154px;
  bottom: -42px;
  right: -2px;
  border-radius: 8px;

  > label {
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    padding: 8px 0 13px 16px;
  }

  > button {
    width: 28px;
    height: 28px;
  }

  > .text {
    > div {
      font-size: 12px;
      line-height: 14px;
    }
  }
}

.input_date {
  display: flex;
  flex-direction: column;

  label {
    font-weight: 500;
    font-size: 14px;
    margin-bottom: 8px;
  }

  .SingleDatePickerInput__withBorder {
    width: 130px;
    height: 36px;
    border: 1px solid #001920;
    border-radius: 4px;
    display: flex;
    flex-direction: row;
    align-items: center;
    cursor: pointer;
  }
  .DateInput {
    width: 90px;
    height: 34px;
    margin: 0;
    padding: 0;
    border-radius: 4px;
  }
  .DateInput_input {
    height: 34px;
    min-height: 34px;
    font-family: 'Urbanist';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    color: black;
    border-bottom: none;
    border-radius: 4px;
    padding: 0 0 0 8px;
    margin: 0;
    cursor: pointer;
  }
  .SingleDatePickerInput_calendarIcon {
    padding: 0;
    margin: 4px 0 0 8px;
  }
}

.CalendarDay__selected,
.CalendarDay__selected:active,
.CalendarDay__selected:hover {
  background-color: #7a48ff;
  border-color: #7a48ff;
}

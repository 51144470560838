#charts {
  margin-top: 32px;

  > .wrapper {
    // margin-top: 16px;
    // max-width: 1118px;
    margin: 16px auto 0 auto;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    flex-wrap: wrap;
    gap: 30px;
    // @media (max-width: 900px) {
    //   margin: 16px;
    //   gap: 0;
    // }
  }
}

/*NAVEGADOR*/
.nav {
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 999;
  padding: 0 16px;
  display: grid;
  grid-template-columns: auto 1fr;
  grid-gap: 1rem;
  grid-template-rows: 56px;
  background-color: #78ffe5;
  background-image: linear-gradient(90deg, #78ffe5 0%, #ab84ff 100%);
}
.nav_container {
  grid-row: 1;
  grid-column: 2;
  display: grid;
  grid-template-columns: 1fr auto auto auto auto;
  grid-gap: 0.5rem;
}
.nav_container > div {
  padding: 0 1rem;
  box-sizing: border-box;
}
button {
  background-color: transparent;
  border: none;
  padding: 1%;
  display: flex;
  align-items: center;
  cursor: pointer;
  font-style: normal;
  font-family: 'Urbanist';
  font-weight: 600;
  font-size: 1rem;
  color: black;
}
// button:hover {
//   color: #5330c9;
// }
button:active {
  color: #5330c9;
  background: rgba(255, 255, 255, 0.4);
  border-radius: 4px;
}
// LOGO
.nav_logo {
  grid-row: 1;
  grid-column: 1;
  display: flex;
  align-items: center;
}
// NAV
.nav_nav {
  grid-row: 1;
  grid-column: 1;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  grid-gap: 1rem;
}
// SETTINGS
.nav_settings {
  grid-row: 1;
  grid-column: 2;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 2rem;
}
// IDIOMAS
.nav_language {
  grid-row: 1;
  grid-column: 3;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
}
.button_language span {
  padding-left: 10px;
  text-transform: uppercase;
}
.nav_nav_link {
  font-style: normal;
  font-family: 'Urbanist';
  font-weight: 600;
  font-size: 1rem;
  color: black;
  cursor: pointer !important;
  padding: 1% 1%;
  align-items: center;
  display: flex;
  text-align: center;
  text-decoration: none;
}
.nav_nav_link:hover {
  color: #5330c9;
}
.nav_nav_link_active {
  color: #5330c9;
  background: rgba(255, 255, 255, 0.4);
  border-radius: 4px;
}
.nav_burger {
  display: none;
}
// DESPLEGABLE IDIOMAS
.nav_MenuLanguage {
  position: absolute;
  top: 58px;
  right: auto;
  z-index: 2;
  width: 200px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding: 1%;
  background-color: white;
}
.button_languageOption {
  width: 100%;
  text-align: left;
  border-radius: 4px;
  padding: 6% 5%;
}
.button_languageOption_active {
  border: 1px solid #ab84ff;
  color: #ab84ff;
}
// USER
.nav_user {
  grid-row: 1;
  grid-column: 4;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.nav_user_text {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
  padding-right: 8px;
  text-align: right;
}
.nav_user_text > p:first-child {
  font-weight: bold;
}
.button_iconUser {
  background-color: white;
  border-radius: 35px;
  padding: 8px;
}
// EXIT
.nav_exit {
  grid-row: 1;
  grid-column: 5;
  display: flex;
  flex-direction: row;
  align-items: center;
}
.nav_exit button:active {
  background-color: transparent;
}
// ICONOS
.icon {
  height: 18px;
  width: auto;
  margin-right: 0.5rem;
}
.iconBig {
  height: 25px;
  width: 25px;
}
.iconWorld {
  height: 25px;
  width: auto;
  margin-right: 0.5rem;
}
.iconUser {
  height: 23px;
  width: 23px;
}
.iconExit {
  height: 23px;
  width: 23px;
}
.iconSelect {
  height: 10px;
  width: auto;
  padding-left: 3px;
}
/*MEDIA QUERYS*/
@media only screen and (max-width: 1800px) {
  /*NAVEGADOR*/
  .nav_container {
    grid-template-columns: 4fr auto auto 1fr auto;
  }
}
@media only screen and (max-width: 1420px) {
}
@media only screen and (max-width: 1250px) {
}
@media only screen and (max-width: 1023px) {
  /*NAVEGADOR*/
  .nav {
    height: 56px;
    grid-template-columns: 1fr auto auto;
    grid-template-rows: auto;
  }
  // SETTINGS
  .nav_settings {
    position: fixed;
    top: 0px;
    height: 56px;
    right: 60px;
  }
  .iconBig {
    height: 25px;
    width: 25px;
  }
  // BURGER
  .nav_burger {
    position: fixed;
    display: flex;
    top: 0px;
    height: 56px;
    right: 0;
    padding: 0 1rem;
  }
  .iconClose {
    height: 25px;
  }
  // NAV DESPLEGABLE
  .nav_container {
    position: fixed;
    overflow: auto;
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: auto auto auto 1fr;
    background-color: white;
    height: 100%;
    width: 65%;
    top: 56px;
    right: 0;
    padding: 2%;
    z-index: 100;
  }
  .nav_container > div {
    padding: 1rem 1rem;
  }
  // USER
  .nav_user {
    grid-row: 1;
    grid-column: 1;
    display: flex;
    flex-direction: row-reverse;
    align-items: flex-start;
    // padding: 3vh 0;
  }
  .button_iconUser {
    margin-right: 0.5rem;
  }
  .nav_user_text {
    align-items: flex-start;
    padding-right: 0;
  }
  .iconUser {
    color: white;
    height: 25px;
    width: 25px;
  }
  //NAV
  .nav_nav {
    grid-row: 2;
    grid-column: 1;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    border-top: 1px solid gray;
    padding: 4vh 0 1vh 0;
  }
  .nav_nav_link {
    font-size: 1rem;
    padding-bottom: 4vh;
  }
  .nav_nav_link_active {
    color: #5330c9;
  }
  .nav_nav_link_active .iconBig {
    fill: #5330c9;
    stroke: #5330c9;
  }

  .button_iconUser {
    background-color: black;
  }
  // EXIT
  .nav_exit {
    grid-row: 4;
    grid-column: 1;
    align-items: flex-start;
  }
  .icon,
  .iconExit,
  .iconWorld {
    height: 25px;
    width: auto;
    margin-right: 0.5rem;
  }
  // IDIOMAS
  .nav_language {
    grid-row: 3;
    grid-column: 1;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    border-top: 1px solid gray;
  }

  /*menú inglés*/
  .nav_MenuLanguage {
    margin-top: 1vh;
    position: initial;
    height: auto;
    width: 100%;
  }
  .button_languageOption {
    // width: 90%;
    text-align: center;
    border-radius: 5px;
    padding: 3% 5%;
  }
}
@media only screen and (max-width: 767px) and (orientation: portrait) {
}
@media only screen and (max-width: 576px) {
}

.logout {
  padding-left: 8px;
}

.logout:hover {
  text-decoration: underline;
}

%item_base {
  user-select: none;
  height: 34px;
  min-height: 34px;
  display: flex;
  align-items: center;
  padding-left: 8px;
  font-size: 14px;
  overflow: hidden;
}

.input_select {
  display: flex;
  flex-direction: column;

  label {
    font-weight: 500;
    font-size: 14px;
    margin-bottom: 8px;
  }

  .input {
    position: relative;
    width: 260px;
    height: 36px;
    background: #ffffff;
    border: 1px solid #001920;
    border-radius: 4px;
    cursor: pointer;

    .selected {
      @extend %item_base;

      border-radius: 4px;
      height: 34px;
      min-height: 34px;
    }

    .items {
      position: absolute;
      background-color: white;
      top: 100%;
      left: 0;
      right: 0;
      z-index: 99;
      border: 1px solid #001920;
      border-bottom-left-radius: 4px;
      border-bottom-right-radius: 4px;
      overflow: auto;
      max-height: 200px;

      .item {
        @extend %item_base;

        &:hover {
          background-color: rgba(0, 0, 0, 0.1);
        }
      }
    }

    .chevron_wrapper {
      width: 24px;
      height: 24px;
      position: absolute;
      top: 6px;
      right: 10px;
      color: #434b67;
      pointer-events: none;
      transition: transform 200ms cubic-bezier(0, 0, 0.2, 1) 0ms;
      &:focus {
        outline: none;
      }
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
}

.wasteColumn {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;

  > div {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
  }
  .number {
    font-weight: 600;
    font-size: 12px;
    line-height: 14px;
    text-align: center;
  }
  .color {
    width: 100%;
    margin-top: 2px;
  }
}
.label {
  width: 100%;
  padding: 2px 5% 0 5%;
  > p {
    width: 14px;
    font-weight: 400;
    font-size: 10px;
    line-height: 10px;
  }
  text-align: center;
  border-top: 1px solid #dee1e3;
  color: #6f7d81;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  text-align: center;
}
